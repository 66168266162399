<template>
  <div>
    <el-dropdown @command="command">
      <span class="el-dropdown-link more">
        <i class="eden-icon-more"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, index) in actions">
          <el-dropdown-item
            v-if="allowAccessFor(action.access)"
            :key="index"
            :icon="action.icon"
            :command="{
              command: action.command,
              component: action.component,
            }"
            :disabled="action.command === 'invoice'"
            >{{ action.label }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :action="'edit'"
      :transaction="transaction"
      @success="emitUpdate"
    />
  </div>
</template>

<script>
export default {
  name: "TransactionActions",
  props: {
    transaction: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    TransactionInformation: () => import("./TransactionInformation"),
    TransactionForm: () => import("../TransactionForm"),
    TransactionPreview: () => import("./TransactionPreview"),
    TransactionDelete: () => import("./TransactionDelete"),
  },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      actions: [
        {
          access: "all",
          icon: "eden-icon-eye",
          label: "View information",
          command: "information",
          component: "transaction-information",
        },
        {
          access: ["superadmin", "admin", "growth", "gardener", "gardener_pro"],
          icon: "el-icon-document",
          label: "Generate invoice",
          command: "invoice",
          component: "transaction-invoice",
        },
        {
          access: ["superadmin", "admin"],
          icon: "eden-icon-edit-write",
          label: "Edit transaction",
          command: "edit",
          component: "transaction-form",
        },
        {
          access: ["superadmin"],
          icon: "eden-icon-delete",
          label: "Delete transaction",
          command: "delete",
          component: "transaction-delete",
        },
      ],
    };
  },
  methods: {
    command(action) {
      this.action.command = action.command;
      this.action.component = action.component;
      setTimeout(() => {
        this.action.visibility = true;
      }, 100);
    },
    emitUpdate(data) {
      this.$emit("action", { action: this.action.command, data });
    },
  },
};
</script>

<style lang="scss"></style>
