<template>
  <div>
    <eden-table-actions :title="title" :show-search="false" />
    <template>
      <p v-if="loading">Loading</p>
      <template v-else>
        <el-table :data="data">
          <el-table-column width="300">
            <template slot="header">
              <div class="table--header">
                <span>Subscription Cycle</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="cycle">
                <span class="font-sm">
                  {{ formatDate(scope.row.start_date, "ddd do, m y") }}</span
                >
                <i class="el-icon-right"></i>
                <span class="font-sm">
                  {{ formatDate(scope.row.end_date, "ddd do, m y") }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Subscription Amount</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatPrice(scope.row.amount) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>Outstanding Amount</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ formatPrice(scope.row.outstanding_amount) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot="header">
              <div class="table--header">
                <span>No of Pauses</span>
              </div>
            </template>
            <template slot-scope="scope">
              <span class="font-sm">
                {{ scope.row.pauses.length }}
              </span>
            </template>
          </el-table-column>
          <el-table-column width="200">
            <template slot="header">
              <div class="table--header">
                <span>Paused Services</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div
                v-if="scope.row.pauses.length"
                class="is-flex is-align-center"
              >
                <el-tag
                  v-for="(service, i) in getPausedServices(scope.row.pauses)"
                  :key="i"
                  :type="setServiceType(service)"
                  >{{ formatText(service) }}</el-tag
                >
              </div>
              <span v-else>-</span>
            </template>
          </el-table-column>
          <el-table-column>
            <template slot-scope="scope">
              <span
                v-if="scope.row.pauses.length"
                class="font-sm text-primary text-cursor text-underline"
                @click="viewSubscription(scope.row)"
              >
                View Pauses
              </span>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </template>
    <customer-subscription-cycle
      :show.sync="showSubscriptionCycle"
      :subscription="subscription"
      @close="subscription = {}"
    />
  </div>
</template>

<script>
import customer from "@/requests/customers/customer";
import CustomerSubscriptionCycle from "@/components/Customers/Individual/Overview/Subscription/CustomerSubscriptionCycle";

export default {
  name: "CustomerSubscriptions",
  props: {
    customer: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { CustomerSubscriptionCycle },
  data() {
    return {
      loading: false,
      data: [],
      showSubscriptionCycle: false,
      subscription: {},
    };
  },
  computed: {
    title() {
      return `${this.data.length || 0} Subscription Cycles`;
    },
    customerId() {
      return this.$route.params.id;
    },
    setCustomer: {
      get() {
        return this.customer;
      },
      set(value) {
        this.$emit("update:customer", value);
      },
    },
  },
  created() {
    this.getCustomerSubscriptions();
  },
  methods: {
    getCustomerSubscriptions() {
      this.loading = true;
      customer
        .subscriptions(this.customerId)
        .then((response) => {
          const { status, data } = response;
          if (status) {
            this.data = data.data;
            this.setCustomer = {
              name: this.data.length ? this.data[0].name : "",
              email: this.data.length ? this.data[0].customer_email : "",
            };
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getPausedServices(pauses) {
      const services = pauses.map((pause) => pause.service);
      return [...new Set(services)];
    },
    viewSubscription(subscription) {
      this.subscription = { ...subscription };
      this.showSubscriptionCycle = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.cycle {
  display: flex;
  align-items: center;

  i {
    margin: 0 20px;
  }
}
</style>
