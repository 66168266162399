<template>
  <div>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <el-button
          type="plain"
          icon="eden-icon-upload"
          class="ml-10"
          disabled
          @click="showTransactionExport = true"
        >
          Export
        </el-button>
      </template>
    </eden-table-actions>
    <template v-if="loading">Loading...</template>
    <template v-else>
      <el-table :data="pageData">
        <el-table-column>
          <template slot="header">
            <span>Amount</span>
          </template>
          <template slot-scope="scope">
            <span>{{ formatPrice(scope.row.amount) }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Type</span>
          </template>
          <template slot-scope="scope">
            <span>{{ formatText(scope.row.payment_type) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="250">
          <template slot="header">
            <span>Services</span>
          </template>
          <template slot-scope="scope">
            <eden-services-list
              v-if="scope.row.services.length"
              :services="scope.row.services"
            />
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Gateway</span>
          </template>
          <template slot-scope="scope">
            <span>{{ formatPaymentGateway(scope.row.payment_gateway) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100">
          <template slot="header">
            <span>Status</span>
          </template>
          <template slot-scope="scope">
            <eden-tag
              v-if="scope.row.payment_status"
              :value="scope.row.payment_status"
            />
          </template>
        </el-table-column>
        <el-table-column width="220">
          <template slot="header">
            <span>Date</span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">
              {{ formatDateAndTime(scope.row.date_paid, "dddd do, m, y") }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="50">
          <template slot-scope="scope">
            <transaction-actions
              :transaction="scope.row"
              @action="updateTransactions($event, scope.$index)"
            />
          </template>
        </el-table-column>
      </el-table>
      <eden-pagination
        v-if="showPagination"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
      />
    </template>
  </div>
</template>

<script>
import * as actions from "@/store/action-types";
import TransactionActions from "@/components/Subscriptions/Transactions/Actions/TransactionActions";

export default {
  name: "CustomerTransactions",
  components: {
    TransactionActions,
  },
  data() {
    return {
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      showTransactionExport: false,
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    transactions() {
      return this.$store.getters.customer_transactions;
    },
    from() {
      return this.transactions.pages[this.page].from;
    },
    to() {
      return this.transactions.pages[this.page].to;
    },
    total() {
      return this.transactions.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Transactions`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.transactions.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getTransactions();
      }
    },
  },
  created() {
    // const pageFetched = !!this.transactions.pages[this.page];
    // if (pageFetched) {
    //   this.setPageData();
    // } else {
    this.getTransactions();
    // }
  },
  methods: {
    getTransactions() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_CUSTOMER_TRANSACTIONS, {
          id: this.customerId,
          page: this.page,
        })
        .then(() => {
          this.setPageData();
          this.loading = false;
          this.pageDataTime = new Date();
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.transactions.pages[this.page].data;
      this.showPagination = true;
    },
    updateTransactions(event, index) {
      switch (event.action) {
        case "edit":
          this.pageData.splice(index, 1, {
            ...this.pageData[index],
            ...event.data,
          });
          break;
        case "delete":
          this.pageData.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
