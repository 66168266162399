<template>
  <el-collapse>
    <el-collapse-item :class="'plain'" :disabled="log.action !== 'pause'">
      <template slot="title">
        <div class="log">
          <div class="log-dates">
            <span class="action" :class="`${log.action}`"></span>
            <span>
              {{ formatDate(log.start_date, "m do, y") }}
              <i class="el-icon-right" />
              {{ formatDate(log.end_date, "m do, y") }}
            </span>
          </div>
          <span v-if="log.action === 'pause'" class="log-amount">
            {{ formatPrice(outstandingTotal) }}
          </span>
          <span v-else class="text-grey-primary">{{
            formatText(log.service)
          }}</span>
        </div>
      </template>
      <div
        v-for="(value, key, i) in outstanding"
        :key="i"
        class="log-service"
        :style="setServiceStyle(key)"
      >
        <p>
          {{ value.services }} {{ key }} services
          <span v-if="key === 'meal'">({{ value.quantity }} meals)</span>
        </p>
        <span>{{ formatPrice(value.amount) }}</span>
      </div>
    </el-collapse-item>
  </el-collapse>
</template>

<script>
export default {
  name: "CustomerPauseLog",
  props: {
    customerPlan: {
      type: Object,
      required: true,
    },
    log: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      outstanding: {},
      outstanding_amount: 0,
    };
  },
  computed: {
    outstandingTotal() {
      return Object.keys(this.outstanding).reduce((total, service) => {
        return total + this.outstanding[service].amount;
      }, 0);
    },
  },
  created() {
    this.sortOutstandingServices();
  },
  methods: {
    sortOutstandingServices() {
      this.log.orders.forEach((order) => {
        const service = order.service.toLowerCase().includes("meal")
          ? "meal"
          : order.service.toLowerCase();
        if (!this.outstanding[service]) {
          this.outstanding[service] = {
            services: 0,
            quantity: 0,
            amount: 0,
          };
        }

        if (service === "meal") {
          this.outstanding.meal.services++;
          this.outstanding.meal.quantity += this.getTotalMealsForDay(
            order.deliver_to_customer
          );
        } else {
          this.outstanding[service].services++;
          this.outstanding[service].quantity++;
        }
      });

      Object.keys(this.outstanding).forEach((service) => {
        const amount = this.getOutstandingServicesPrice(service);
        this.outstanding[service].amount = amount;
        this.outstanding_amount = this.outstanding_amount + amount;
      });
      this.$emit("set-amount", this.outstanding_amount);
    },
    getOutstandingServicesPrice(service) {
      switch (service) {
        case "beauty":
          return this.getBeautyPrice();
        case "cleaning":
          return this.getCleaningPrice();
        case "laundry":
          return this.getLaundryPrice();
        case "meal":
          return this.getMealPrice();
        default:
          break;
      }
    },
    getBeautyPrice() {
      let { beauty } = this.customerPlan;
      if (!beauty) {
        return 0;
      }

      if (!beauty.amount) {
        return 0;
      }

      const numberOfServices = this.timesPerCycle(beauty.frequency);
      const beautyPrice = beauty.amount / numberOfServices;
      return this.outstanding.beauty.services * beautyPrice;
    },
    getCleaningPrice() {
      let { cleaning } = this.customerPlan;

      if (!cleaning) {
        return 0;
      }

      if (!cleaning.amount) {
        return 0;
      }

      const numberOfServices = this.timesPerCycle(cleaning.frequency);
      const cleaningPrice = cleaning.amount / numberOfServices;
      const price = this.outstanding.cleaning.services * cleaningPrice;

      this.outstanding.cleaning.amount = price;
      return price;
    },
    getLaundryPrice() {
      let { laundry } = this.customerPlan;
      if (!laundry) {
        return 0;
      }

      if (!laundry.amount) {
        return 0;
      }

      const numberOfServices = this.timesPerCycle(laundry.frequency);
      const laundryPrice = laundry.amount / numberOfServices;
      return this.outstanding.laundry.services * laundryPrice;
    },
    getMealPrice() {
      let { meal } = this.customerPlan;
      let { qty, frequency, service_day, amount } = meal;
      if (!meal) {
        return 0;
      }

      let basePrice;

      if (frequency === "daily") {
        basePrice = amount / (qty * 4);
      } else {
        const delivery = (service_day.length - 1) * 1000 * 4;
        const price = amount - delivery;
        basePrice = price / (qty * 4);
      }
      return this.outstanding.meal.quantity * basePrice;
    },
    getTotalMealsForDay(delivery) {
      const { meal_per_delivery } = this.customerPlan.meal;
      const day = this.getWeekday(delivery).toLowerCase();
      return meal_per_delivery[day] || 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.log {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--eden-grey-secondary);
  font-weight: 400;

  &-dates {
    span {
      font-size: 0.825rem !important;
      color: var(--eden-grey-tertiary);
    }
    .action {
      display: inline-block;
      height: 7px;
      width: 7px;
      border-radius: 100px;
      background: var(--eden-orange-primary);
      margin-right: 15px;

      &.unpause {
        background: var(--eden-green-primary);
      }
    }

    i {
      margin: 0 10px;
    }
  }

  &-amount {
    color: var(--eden-grey-primary);
    font-weight: 500;
    font-size: 0.825rem;
  }

  &-service {
    border: 1px solid;
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    p,
    span {
      font-size: 0.825rem;
      color: var(--eden-grey-primary);
    }
  }
}

.el-collapse-item {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid var(--eden-grey-septenary);
  padding: 14px;
}
</style>
