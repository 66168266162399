<template>
  <el-drawer
    :visible.sync="shouldShow"
    direction="rtl"
    @close="closeEvent"
    size="30%"
  >
    <template slot="title">
      <span>{{ title }}</span>
    </template>
    <div class="el-drawer--content">
      <div class="el-drawer--content__body">
        <customer-pause-log
          v-for="(log, index) in subscriptionLogs"
          :key="index"
          :log="log"
          :customer-plan="subscription.plan"
        />
      </div>
    </div>
    <div class="el-drawer--footer">
      <div class="w-100">
        <div class="summary">
          <p>Total amount owed:</p>
          <span>- {{ formatPrice(outstandingAmount) }}</span>
        </div>
        <div class="summary">
          <p>Subscription amount:</p>
          <span>{{ formatPrice(subscriptionAmount) }}</span>
        </div>
        <div class="summary">
          <p>Amount charge (for next billing cycle):</p>
          <span>{{ formatPrice(subscriptionAmount - outstandingAmount) }}</span>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import CustomerPauseLog from "@/components/Customers/Individual/Overview/Pauses/CustomerPauseLog";
import { formatPauseLogs } from "../Pauses/format-data";

export default {
  name: "CustomerSubscriptionCycle",
  components: {
    CustomerPauseLog,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    subscription: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    title() {
      const { start_date, end_date } = this.subscription;
      const cycle = this.formatSubscriptionDate(start_date, end_date);
      return `${cycle.start} - ${cycle.end}`;
    },
    outstandingAmount() {
      if (this.subscription && this.subscription.outstanding_amount) {
        return this.subscription.outstanding_amount;
      }
      return 0;
    },
    subscriptionAmount() {
      const plan = this.subscription && this.subscription.plan;
      if (plan) {
        return this.subscription.amount;
      }
      return 0;
    },
    subscriptionLogs() {
      const { pauses, outstanding_services } = this.subscription;
      if (pauses && outstanding_services) {
        return formatPauseLogs(
          pauses,
          this.sortArrayIntoObject(outstanding_services, "id")
        );
      }
      return [];
    },
  },
  methods: {
    closeEvent() {
      this.$emit("close");
      this.shouldShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.summary {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-size: 0.875rem;
  }

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  &:last-child span {
    font-weight: 600;
    font-size: 1.125rem;
  }
}
</style>
