<template>
  <div>
    <eden-table-actions :title="title" :show-search="false" />
    <eden-loader v-if="loading" />
    <template v-else>
      <el-table :data="cards">
        <el-table-column width="80">
          <template slot="header">
            <span>Card Type</span>
          </template>
          <template slot-scope="scope">
            <img
              class="image"
              :src="getCardImage(scope.row.brand, 'svg')"
              :alt="scope.row.brand"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Card Number</span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">{{
              formatDebitCard(scope.row.bin, scope.row.last4)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Valid</span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm"
              >{{ scope.row.exp_month }} / {{ scope.row.exp_year }}</span
            >
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Gateway</span>
          </template>
          <template slot-scope="scope">
            <div class="card-type">
              <img
                class="image"
                :src="getCardImage(scope.row.gateway_channel, 'png')"
                :alt="scope.row.gateway_channel"
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header">
            <span>Added on</span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">{{
              formatDateAndTime(scope.row.created_at, "dddd do, m, y")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="allowAccessFor(['admin'])" width="80">
          <template slot-scope="scope">
            <el-dropdown @command="command">
              <span class="el-dropdown-link more">
                <i class="eden-icon-more" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  :icon="'eden-icon-delete'"
                  :command="{
                    ...scope.row,
                    index: scope.$index,
                    action: 'delete',
                  }"
                  >Delete card</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <eden-confirm-dialog
      :show.sync="showCustomerCardDelete"
      title="Delete Card"
      button-type="danger"
      button-text="Delete"
      @confirm="deleteCard"
    >
      <p>
        Are you sure you want to delete this card <br />
        <span v-if="card.bin" class="text-bold"
          >{{ formatDebitCard(card.bin, card.last4) }}?</span
        >
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import cards from "@/requests/customers/customer/cards";

export default {
  name: "CustomerCards",
  props: {
    customerEmail: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      cards: [],
      card: {},
      showCustomerCardDelete: false,
    };
  },
  computed: {
    customerId() {
      return this.$route.params.id;
    },
    title() {
      return `${this.cards.length} Cards`;
    },
  },
  watch: {
    customerEmail() {
      if (this.customerEmail) {
        this.getCards();
      }
    },
  },
  methods: {
    getCards() {
      this.loading = true;
      cards
        .index(this.customerEmail)
        .then((response) => {
          const { status, message, data } = response;
          if (status) {
            this.cards = data.data;
            this.loading = false;
          } else {
            this.$message.error(message);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    command(command) {
      if (command.action === "delete") {
        this.card = { ...command };
        this.showCustomerCardDelete = true;
      }
    },
    deleteCard() {
      this.deleting = true;
      const { id, index } = this.card;
      cards
        .delete(this.customerId, id)
        .then((response) => {
          const { status, message } = response;
          if (status) {
            this.cards.splice(index, 1);
            this.deleting = false;
          } else {
            this.$message.error(message);
            this.deleting = false;
          }
        })
        .catch((error) => {
          this.deleting = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.image {
  height: 14px;
  width: auto;
}
</style>
