import axios from "axios";

export default {
  index(email) {
    return axios.get(`customer/${email}/payment_details`);
  },

  delete(customerId, cardId) {
    return axios.get(`customer/${customerId}/delete_card/${cardId}`);
  },
};
