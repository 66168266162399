export const formatPauseLogs = (logs, orders) => {
  let result = [];
  logs.forEach((log) => {
    let newLog = {
      action: log.action,
      service: log.service,
      start_date: log.start_date,
      end_date: log.end_date,
      orders: [],
    };
    log.order_ids.forEach((id) => {
      if (orders[id] && !orders[id].is_one_time_order) {
        newLog.orders.push(orders[id]);
      }
    });
    result.push(newLog);
  });
  return result;
};
