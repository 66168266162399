<template>
  <div>
    <eden-page-header
      :title="'Customers'"
      :subtitle="formatName(customer.name)"
      :section="'Subscriptions'"
    />
    <el-tabs v-model="tab">
      <el-tab-pane label="Subscriptions" name="subscriptions">
        <customer-subscriptions :customer.sync="customer" />
      </el-tab-pane>
      <el-tab-pane label="Transactions" name="transactions">
        <customer-transactions />
      </el-tab-pane>
      <el-tab-pane label="Cards" name="cards">
        <customer-cards :customer-email="customer.email" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import CustomerSubscriptions from "@/components/Customers/Individual/Overview/Subscription/CustomerSubscriptions";
import CustomerTransactions from "@/components/Customers/Individual/Overview/Subscription/CustomerTransactions";
import CustomerCards from "@/components/Customers/Individual/Overview/Subscription/CustomerCards";

export default {
  name: "CustomerSubscriptionsHistory",
  components: {
    CustomerCards,
    CustomerSubscriptions,
    CustomerTransactions,
  },
  data() {
    return {
      customer: {
        name: "",
        email: "",
      },
      tab: "subscriptions",
    };
  },
};
</script>
